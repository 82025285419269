<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="updateRoute('/profile-settings')">
            <ion-icon :icon="settings"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click.prevent="updateRoute('messages')">
            <ion-icon :ios="mail" :md="mail"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>{{authUser.username}}</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">
        <!-- Profile Top -->
        <div v-if="showBlock === true" class="bg-dark">
          <ProfileTop :is-admin="true" :profile-data="authUser" />
        </div>
        <!-- Profile Feed
        <div class="theme-light ion-padding-bottom">
          <FeedWrapper feed-type="profile" :profile-id="profileId" :place-id="0" />
        </div> -->
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="profile-view" />
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle } from '@ionic/vue';
import { settings, mail } from 'ionicons/icons';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
//import FeedWrapper from '../components/PostFeed.vue';
import ProfileTop from '../components/ProfileTop.vue';
import doAuth from '../services/auth';
import Tabs from '../components/Tabs.vue';

export default defineComponent({
  name: 'ProfileView',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle,
    //FeedWrapper,
    ProfileTop,
    Tabs
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const profileId = ref(0);
    const showBlock = ref(false);


    onMounted(() => {
      if(authUser.value) {
        showBlock.value = true
        profileId.value = authUser.value.id
      }
    });


    function updateRoute(val) {
      router.push(val)
    }

    return {
      showBlock, authUser, router, profileId, store, settings, mail, updateRoute
    }
  }
});
</script>

<style scoped>

.theme-light {
  background:#f4f5f8!important;
}
</style>