<template>
  <div style="padding-left:10px; padding-right:10px;">
    <!-- Top Row -->
    <ion-row>
      <!-- Avatar Col -->
      <ion-col size="3">
        <div>
            <ion-thumbnail class="margin-auto" @click="updateRoute('profile-photo')">
              <img :src="avatar" class="img-avatar w-72" />
            </ion-thumbnail>
        </div>
        <div v-if="profileName != null" class="aw-small-font text-center" style="padding-top:5px;">
          {{profileName}}
        </div>
      </ion-col>
      <!-- Right Col -->
      <ion-col size="9">
        <!-- Followers -->
        <ion-row>
          <ion-col size="6">
            <div class="ion-text-center aw-small-font" style="cursor:pointer;" @click.prevent="updateRoute('followers')">
              {{followerCount}}<br>
              Followers
            </div>
          </ion-col>
          <ion-col size="6">
            <div class="ion-text-center aw-small-font" style="cursor:pointer;" @click.prevent="updateRoute('following')">
              {{followingCount}}<br>
              Following
            </div>
          </ion-col>
        </ion-row>

        <!-- Actions -->
        <ion-row v-if="isAdmin === true">
          <ion-col size="12">
            <div class="ion-text-center">
              <ion-button size="small" color="light" style="width:100%;" @click.prevent="updateRoute('profile-edit')">
                Edit Profile
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
        <div v-else>
          <ion-row v-if="authUser">
            <ion-col v-if="authUser.id != profileId" size="6">
              <div class="ion-text-center">
                <ion-button size="small" color="light" style="width:100%;" @click.prevent="follow()">
                  Follow
                </ion-button>
              </div>
            </ion-col>
            <ion-col v-if="authUser.id != profileId" size="6">
              <div class="ion-text-center">
                <ion-button size="small" color="light" style="width:100%;" @click.prevent="updateRoute('message')">
                  Message
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
        </div>

      </ion-col>
    </ion-row>
    <!-- Location Row -->
    <ion-row v-if="formattedLocation != null">
      <ion-col size="12">
        {{formattedLocation}}
      </ion-col>
    </ion-row>
    <!-- About Row -->
    <ion-row v-if="shortDescription != null" style="padding-bottom:10px;">
      <ion-col size="12">
        {{shortDescription}}
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { IonRow, IonCol, IonThumbnail, IonButton, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
  name: 'ProfileTop',
  props: ['isAdmin', 'profileData'],
  components: {
    IonRow, IonCol, IonThumbnail, IonButton
  },
  setup(props) {
    const store = useStore();
    const storedProfilePhoto = computed(() => store.state.authUser.profile_photo_path);
    const authUser = computed(() => store.state.authUser);
    const router = useRouter();
    const avatar = ref('https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg');
    const profileName = ref(null);
    const formattedLocation = ref(null);
    const profileType = ref(null);
    const shortDescription = ref(null);
    const followerCount = ref(0);
    const followingCount = ref(0);
    const profileId = ref(0);
    const timeout = { default: 6000 }
    const toastMessage = ref(null);

    console.log(props)

    onMounted(() => {
      // If profile data 
      if(props.profileData) {
        profileId.value = props.profileData.id
        getFollowerCount()
        getFollowingCount()
        // If avatar
        if(props.profileData.profile_photo_path != null) {
          avatar.value = props.profileData.profile_photo_path
        }
        // If Name
        if(props.profileData.name != null) {
          profileName.value = props.profileData.name
        }
        // If Short Description
        if(props.profileData.profile.short_description != null) {
          shortDescription.value = props.profileData.profile.short_description
        }
        // Location
        if(props.profileData.location_id != null) {
          if(props.profileData.location.city != null) {
            formattedLocation.value = props.profileData.location.city
          }
          if(props.profileData.location.state != null) {
            formattedLocation.value = formattedLocation.value + ', ' + props.profileData.location.state
          }
        }
      }
    });

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function updateRoute(r) {
      var path = null
      if(r == 'followers' || r == 'following') {
        path = '/' + r + '/' + props.profileData.username
      } else {
        if(r == 'profile-photo') {
          if(props.isAdmin === true) {
            path = '/update-profile-photo'
          } else {
            path = '/profile-photo/' + props.profileData.username
          }
        } else {
          if(r == 'message') {
             path = '/send-message/' + props.profileData.username
          } else {
            path = r
          }
        }
      }
      router.push(path)
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    function getFollowerCount() {
      presentLoading()
      apiClient.get('/api/get/follower_count/user_id/'+profileId.value, 
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
        followerCount.value = response.data
      });
      
    }

    function getFollowingCount() {
      presentLoading()
      apiClient.get('/api/get/following_count/user_id/'+profileId.value, 
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
        followingCount.value = response.data
      });
      
    }

    function follow() {
      presentLoading()
      apiClient.post('/api/post/follower', 
        { 
          user_id: profileId.value,
          follower_id: authUser.value.id    
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          if(response.data.message == 'success') {
            toastMessage.value = 'You are now following ' + profileName.value
            followerCount.value = followerCount.value + 1
          }
          if(response.data.message == 'fail') {
            toastMessage.value = 'Something went wrong.'
          }
          if(response.data.message == 'fail-record-exists') {
            toastMessage.value = 'You are already following this account' 
          }
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'You are not authorized to do that.'
          }
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in.'
          }
          openToast()
        }).catch(error => {
          console.log(error)
        });     
    }

    return {
     avatar, profileName, formattedLocation, profileType, shortDescription, followerCount, followingCount, updateRoute, router, storedProfilePhoto, authUser, profileId, follow
    }

  },
  watch: {
    storedProfilePhoto: function() {
      this.avatar = this.storedProfilePhoto
    }
  } 
}); 
</script>

<style scoped>
ion-thumbnail {
  width:100% !important;
  height: 100% !important;
  max-width: 72px !important;  
  max-height: 72px !important;
}
.theme-light {
  background:#f4f5f8!important;
}
.aw-small-font {
  font-size:12px!important;
}
.w-72 {
  width:100% !important;
  height: 100% !important;
  max-width: 72px !important;  
  max-height: 72px !important; 
}

</style>